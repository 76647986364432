import { ActionContext } from 'vuex'

import {
	IForm,
	ISubmissionConfiguration,
	IConfigurationOption,
} from '@/models/worklists/outcome'
import { IPlaceOfService } from '@server/api/models/fe/shared'
import { IPublishedPlanAssociation } from '@server/api/models/fe/planAssociation'

interface IWorklistState {
	form: IForm
	submission: ISubmissionConfiguration
	fields: {
		placesOfService: IPlaceOfService[]
	}
	plans: IPublishedPlanAssociation[]
	submissionConfigurations: ISubmissionConfiguration[]
}

const getInitialState = () => ({
	form: {
		id: '',
		name: '',
	},
	submission: {
		id: '',
		name: '',
	},
	fields: {
		placesOfService: [],
	},
	submissionConfigurations: [],
})

const mutations = {
	setForm(state: IWorklistState, form: IForm) {
		state.form = form
	},
	setSubmission(state: IWorklistState, submission: ISubmissionConfiguration) {
		state.submission = submission
	},
	setPlacesOfService(
		state: IWorklistState,
		placesOfService: IPlaceOfService[]
	) {
		state.fields.placesOfService = placesOfService
	},
	setPlans(state: IWorklistState, plans: IPublishedPlanAssociation[]) {
		state.plans = plans
	},
	setSubmissionConfigurations(
		state: IWorklistState,
		configurations: ISubmissionConfiguration[]
	) {
		state.submissionConfigurations = [
			...new Map(
				[...state.submissionConfigurations, ...configurations].map(
					configuration => [configuration.id, configuration]
				)
			).values(),
		]

		state.submissionConfigurations.sort((a, b) => a.name.localeCompare(b.name))
	},
	resetSubmissionConfigurations(state: IWorklistState) {
		state.submissionConfigurations = []
	},
}

const getters = {
	getForm(state: IWorklistState) {
		const form = state.form

		if (form.id && form.name) {
			return form
		}

		return null
	},
	getPlacesOfService(state: IWorklistState) {
		return state.fields.placesOfService
	},
	getPlans(state: IWorklistState) {
		return state.plans
	},
	getSubmissionConfigurations(state: IWorklistState) {
		if (state.submission?.id) {
			const submissionIndex = state.submissionConfigurations.findIndex(
				configuration => configuration.id === state.submission.id
			)

			// Move selected submission to the top of the list for ease of use
			if (submissionIndex > -1) {
				const [submission] = state.submissionConfigurations.splice(
					submissionIndex,
					1
				)
				state.submissionConfigurations.unshift(submission)
			}
		}

		return state.submissionConfigurations
	},
}

const actions = {
	setForm({ commit }: ActionContext<IWorklistState, any>, form: IForm) {
		commit('setForm', form)
		commit('setSubmission', null)
	},
	setSubmission(
		{ commit }: ActionContext<IWorklistState, any>,
		submission: ISubmissionConfiguration
	) {
		commit('setForm', null)
		commit('setSubmission', submission)
	},
	createFormList({ state }: any, options: IForm[]) {
		const form = state.form
		if (form && form.id && form.name) {
			return moveMatchedConfigurationOption(form.id, options) as IForm[]
		}
		return options
	},
	setPlacesOfService(
		{ commit }: ActionContext<IWorklistState, any>,
		placesOfService: string[]
	) {
		commit('setPlacesOfService', placesOfService)
	},
	setPlans(
		{ commit }: ActionContext<IWorklistState, any>,
		plans: IPublishedPlanAssociation[]
	) {
		commit('setPlans', plans)
	},
	setSubmissionConfigurations(
		{ commit }: ActionContext<IWorklistState, any>,
		configurations: ISubmissionConfiguration[]
	) {
		commit('setSubmissionConfigurations', configurations)
	},
	resetSubmissionConfigurations({
		commit,
	}: ActionContext<IWorklistState, any>) {
		commit('resetSubmissionConfigurations')
	},
}

// Store user selected configuration for easier future selections
const moveMatchedConfigurationOption = (
	id: string,
	options: IConfigurationOption[]
) => {
	return [
		...options.filter(option => option.id === id),
		...options.filter(option => option.id !== id),
	]
}

export default {
	namespaced: true,
	state: getInitialState,
	getters,
	mutations,
	actions,
}
